<template>
  <div>
    <div class="box">
      <div class="top">
        <div class="line"></div>
        <div style="margin: 0 10px">可充值额度 (可用额度不足时请及时充值)</div>
        <el-button type="primary" size="small" @click="goBack">返回</el-button>
      </div>
      <div class="bottom">
        <div>
          <div class="num">{{ totalRechargeAmount }}</div>
          <div>可用额度(元)</div>
        </div>
        <div>
          <div class="num">{{ totalRechargeAmount }}</div>
          <div>总充值额度(元)</div>
        </div>
        <div>
          <div class="num">{{ consumeAmount }}</div>
          <div>已消耗额度(元)</div>
        </div>
      </div>
    </div>
    <el-radio-group v-model="radio" style="margin: 30px 0" @input="change">
      <el-radio-button label="额度充值明细"></el-radio-button>
      <el-radio-button label="额度消耗明细"></el-radio-button>
    </el-radio-group>
    <el-table
      v-if="radio == '额度充值明细'"
      :data="tableData"
      :header-cell-style="{
        background: '#f8f8f9',
        color: '#515a6e',
        fontSize: '13px',
        textAlign: 'center'
      }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="rechargeAmount" label="充值金额(单位：元)">
      </el-table-column>
      <el-table-column prop="releaseTime" label="日期"> </el-table-column>
      <el-table-column prop="paidType" label="类型">
        <template slot-scope="{ row }">
          {{ row.paidType ? '微信' : '手动' }}
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-else
      :data="tableData1"
      :header-cell-style="{
        background: '#f8f8f9',
        color: '#515a6e',
        fontSize: '13px',
        textAlign: 'center'
      }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="topicNo" label="专题活动编号"> </el-table-column>
      <el-table-column prop="topicName" label="专题活动名称"> </el-table-column>
      <el-table-column prop="rewardAmount" label="奖励金额(单位：元)">
      </el-table-column>
      <el-table-column prop="rewardCompleteTime" label="日期">
      </el-table-column>
    </el-table>
    <div class="fy">
      <div></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog title="额度充值" :visible.sync="dialogVisible" width="400px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="充值金额" prop="rechargeAmount">
          <el-input v-model="ruleForm.rechargeAmount"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getStoreFundsStatisticsBySubStore,
  getStoreRechargePageBySubStore,
  getUserTopicPageBySubStore,
  rechargeByBank
} from '../../../api/user'
export default {
  data() {
    return {
      ruleForm: {
        rechargeAmount: ''
      },
      rules: {
        rechargeAmount: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      dialogVisible: false,
      tableData: [],
      tableData1: [],
      radio: '额度充值明细',
      consumeAmount: '',
      totalRechargeAmount: '',
      balanceAmount: '',
      total: 0,
      size: 20,
      current: 1,
      id: ''
    }
  },
  created() {
    console.log(this.$route.query.id)
    this.id = this.$route.query.id
    this.getStoreFundsStatisticsBySubStore()
    this.getStoreRechargePageBySubStore()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = await rechargeByBank(this.ruleForm)
          this.dialogVisible = false
          if (data.code == 200) {
            this.$message({
              type: 'success',
              message: '充值成功,等待审核'
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    change() {
      console.log(this.radio)
      this.current = 1
      if (this.radio == '额度充值明细') {
        this.getStoreRechargePageBySubStore()
      } else {
        this.getUserTopicPageBySubStore()
      }
    },
    async getUserTopicPageBySubStore() {
      const { data } = await getUserTopicPageBySubStore({
        pageSize: this.size,
        pageNum: this.current,
        listType: 2,
        storeId: this.id
      })
      console.log(data)
      this.tableData1 = data.list
      this.total = data.total * 1
    },
    async getStoreRechargePageBySubStore() {
      const { data } = await getStoreRechargePageBySubStore({
        pageSize: this.size,
        pageNum: this.current,
        storeId: this.id
      })
      console.log(data)
      this.tableData = data.list
      this.total = data.total * 1
    },
    handleSizeChange(val) {
      this.size = val
      if (this.radio == '额度充值明细') {
        this.getStoreRechargePageBySubStore()
      } else {
        this.getUserTopicPageBySubStore()
      }
    },
    handleCurrentChange(val) {
      this.current = val
      if (this.radio == '额度充值明细') {
        this.getStoreRechargePageBySubStore()
      } else {
        this.getUserTopicPageBySubStore()
      }
    },
    async getStoreFundsStatisticsBySubStore() {
      const { data } = await getStoreFundsStatisticsBySubStore({
        id: this.id
      })
      console.log(data)
      this.consumeAmount = data.consumeAmount || 0
      this.totalRechargeAmount = data.totalRechargeAmount || 0
      this.balanceAmount = data.balanceAmount || 0
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  border-bottom: 1px dashed #e5e5e5;
  .top {
    display: flex;
    align-items: center;
    font-size: 16px;
    .line {
      width: 4px;
      height: 15px;
      background-color: #0088ff;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 400px 30px 140px;
    box-sizing: border-box;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
    }
    .num {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.fy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
